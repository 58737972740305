@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");

body {
  margin: 0;
  padding: 0;
  background-image: linear-gradient(
    to right top,
    rgba(23, 24, 27, 1),
    rgba(23, 24, 27, 1)
  );
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.5;
  color: #ebe7ef;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  min-height: 80rem;
}

main {
  width: 90%;
  max-width: 100rem;
  margin: auto;
}

.juice-section {
  margin-top: 6rem;
  margin-bottom: 6rcap;
}

.ca-address {
  font-family: Changa One, Impact, sans-serif;
  font-size: 2rem;
  display: flex;
  justify-content: center;
}

main h2 {
  perspective: none;
  color: rgb(254 111 57 / 1);
  font-family: Changa One, Impact, sans-serif;
  font-size: 70px;
  text-decoration: none;
  transform: none;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

ul .main-h2-h2 {
  margin: 0 0 0.5rem 0;
}

.main-h2-h2 {
  text-align: center;
  font-family: Changa One, Impact, sans-serif;
  margin: 0 0 2rem 0;
  color: #ffffff;
  font-size: 50px;
  text-transform: uppercase;
}
main h3 {
  text-align: center;
  font-family: Changa One, Impact, sans-serif;
  margin: 0 0 2rem 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 2rem;
  padding: 0 1rem;
}

.juice-section ul {
  list-style: none;
  padding: 0rem;
  margin: 0;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: flex-end;
}

.juice-section li {
  width: 25rem;
  text-align: center;
  background: #3861fb;
  border-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80rem;
}

.juice-section li img {
  width: 100%;
  border-radius: 10px 10px 0 0;
  background: rgb(254 111 57 / 1);
  margin-bottom: 20px;
}

.juice-section li h3 {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
}

.juice-section li p {
  font-size: 1.2rem;
  margin-top: 0;
  padding: 0.2rem 1rem; /* Increase the padding */
}

.juice-section .btn {
  text-decoration: none;
  color: #3861fb;
  background: #fbe200;
  padding: 10px;
  border-radius: 10px;
  margin-left: 20px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Changa One, Impact, sans-serif;
}

#examples {
  margin: 3rem auto;
}

#examples h2 {
  margin: 10rem 0rem 3rem 0rem;
  text-align: left;
  color: rgba(233, 230, 230, 0.828);
}

#examples menu {
  margin: 1rem 0;
  padding: 0;
  display: flex;
  gap: 0.5rem;
  list-style: none;
}

#examples menu button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  color: #3f1070;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#examples menu button:hover {
  background-color: #1b082f;
  color: #ebe7ef;
}

#examples menu button.active {
  background-color: #7925d3;
  color: #ebe7ef;
}

#tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  background-color: #2f1d43;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

#tab-content h3 {
  margin: 0;
}

#tab-content code {
  font-size: 1rem;
}



@media screen and (min-width: 768px) and (max-width: 1024px) {
  main h2 {
    font-size: 3rem;
  }

  .main-h2-h2 {
    font-size: 2.5rem;
  }

  main h3 {
    font-size: 1.5rem;
  }

  .juice-section ul {
    flex-wrap: wrap;
  }

  .juice-section li {
    width: 48%;
    margin-bottom: 1rem;
  }
}