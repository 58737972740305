body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.section-hero {
  -webkit-text-fill-color: inherit;
  background-color: #3861fb;
  background-position: 100%;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: scroll;
  background-clip: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 9rem 2.5rem 0rem;
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.content {
  text-align: center;
  flex-direction: column;
  flex: 0 auto;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 75rem;
  padding-left: 0;
  padding-right: 0;
  display: block;
}

._2-column-grid {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  text-align: center;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  width: 100%;
  display: grid;
}

.block-hero {
  width: 100%;
}

.heading.h1 {
  opacity: 0.9;
  color: #ffffff;
  font-family: Changa One, Impact, sans-serif;
  font-size: 50px;
}

.heading.h1::before {
  content: attr(data-content);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: transparent;
  text-shadow: 2px 2px 0px rgba(255, 255, 255, 0.8),
    -2px 2px 0px rgba(255, 255, 255, 0.8), 2px -2px 0px rgba(255, 255, 255, 0.8),
    -2px -2px 0px rgba(255, 255, 255, 0.8);
  z-index: -1;
}

.text-span-3 {
  color: #ffffff;
  font-size: 90px;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(131deg, #fff, #fe6f39 51%, #fbe200);
  -webkit-background-clip: text;
  background-clip: text;
}

.link-block {
  color: var(--white);
  text-decoration: none;
}

.heading-2.main {
  opacity: 1;
  color: #fbe200;
  -webkit-text-stroke-width: 1px;
  mix-blend-mode: normal;
  background: #fe6f39;
  border-radius: 10px;
  width: 350px;
  margin: 0 auto;
}

@media screen and (min-width: 1280px) {
  .heading-2 {
    pointer-events: auto;
    transform: none;
  }
}

.heading-2 {
  perspective: none;
  color: #ffb2bf;
  -webkit-text-stroke-width: 0px;
  -webkit-text-stroke-color: var(--black);
  font-family: Changa One, Impact, sans-serif;
  font-size: 70px;
  text-decoration: none;
  transform: none;
}

element.style {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
}

.wiggle:hover {
  animation: wiggle 0.5s ease-in-out 2; /* wiggle twice on hover */
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .section-hero {
    padding: 9rem 1rem 0rem;
  }

  .heading.h1 {
    font-size: 36px;
  }

  .text-span-3 {
    font-size: 48px;
  }

  .heading-2.main {
    font-size: 24px;
    width: auto;
    padding: 0.5rem 1rem;
  }

  ._2-column-grid {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .block-hero {
    width: 100%;
    margin-bottom: 1rem;
  }
}
