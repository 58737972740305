.navbar {
  background: rgb(254 111 57 / 1);
  color: #fbe200;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9%;
  position: fixed;
  top: 0; /* Make sure the header is fixed at the top */
  width: 100%;
  font-family: Changa One, Impact, sans-serif;
  z-index: 1000;
  transition: padding 0.5s ease-in-out;
  text-transform: uppercase;
}

.navbar div {
  display: flex;
  align-items: center;
}

.navbar.scrolled {
  padding: 0.5rem; /* Adjust padding when scrolled */
  transition: font-size 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.navbar.scrolled .logo,
.navbar.scrolled h1 {
  display: none; /* Hide the logo and heading when scrolled */
}

.navbar h1 {
  transition: font-size 0.5s ease-in-out;
}

.logo {
  width: 90px;
  transition: transform 0.5s ease-in-out;
}

.logo-img {
  max-width: 100%;
  height: auto;
}

.navbar nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.navbar nav li {
  margin: 0 1rem;
}

.navbar nav a {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  font-size: x-large;
}

.navbar nav a.btn {
  text-decoration: none;
  color: #3861fb;
  background: #fbe200;
  padding: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 0rem 0.5rem;
  }

  .logo {
    width: 80px;
  }

  .navbar nav ul {
    flex-direction: row;
    align-items: flex-start;
  }

  .navbar nav li {
    margin: 0rem 0.3rem;
  }

  .navbar nav a {
    font-size: large;
  }
}

/* Add this to ensure the home section is not hidden behind the header */
.section-hero {
  padding-top: 6rem; /* Adjust padding-top to accommodate the fixed header */
}
