@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Roboto+Condensed:wght@400;700&display=swap");

.juice-section {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.juice-section h2 {
  perspective: none;
  color: rgb(254 111 57 / 1);
  font-family: Changa One, Impact, sans-serif;
  font-size: 70px;
  text-decoration: none;
  transform: none;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.juice-section .main-h2-h2 {
  text-align: center;
  font-family: Changa One, Impact, sans-serif;
  margin: 0 0 2rem 0;
  color: #ffffff;
  font-size: 50px;
  text-transform: uppercase;
}

.juice-section h3 {
  text-align: center;
  font-family: Changa One, Impact, sans-serif;
  margin: 0 0 2rem 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 2rem;
  padding: 0 1rem;
}

.juice-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
}

.juice-section li {
  width: 25rem;
  text-align: center;
  background: #3861fb;
  border-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.juice-section li img {
  width: 100%;
  border-radius: 10px 10px 0 0;
  background: rgb(254 111 57 / 1);
  margin-bottom: 20px;
}

.juice-section li h2 {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
}

.juice-section li p {
  font-size: 1.2rem;
  margin-top: 0;
  padding: 0.2rem 1rem;
}

.juice-section .btn {
  text-decoration: none;
  color: #3861fb;
  background: #fbe200;
  padding: 10px;
  border-radius: 10px;
  margin-left: 20px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: Changa One, Impact, sans-serif;
}

@media screen and (max-width: 768px) {
  .juice-section h2 {
    font-size: 4rem;
  }

  .juice-section .main-h2-h2 {
    font-size: 1.5rem;
  }

  .juice-section h3 {
    font-size: 1.2rem;
  }

  .juice-section ul {
    flex-direction: column;
    align-items: center;
  }

  .juice-section li {
    width: 90%;
    margin-bottom: 1rem;
  }

  .juice-section .btn {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .juice-section h2 {
    font-size: 4rem;
  }

  .juice-section .main-h2-h2 {
    font-size: 2.5rem;
  }

  .juice-section h3 {
    font-size: 1.5rem;
  }

  .juice-section ul {
    flex-wrap: wrap;
  }

  .juice-section li {
    width: 48%;
    margin-bottom: 1rem;
  }
}
