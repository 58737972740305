.section-full {
  outline-offset: 0px;
  text-align: center;
  border: 1px #000;
  outline: 3px #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1280px) {
  .scroll-wrap {
    color: var(--white);
    background-color: #d99cda;
  }
}

.scroll-wrap {
  background-color: rgb(254 111 57 / 1);
  flex-direction: row;
  min-height: 6.25rem;
  display: flex;
  overflow: hidden;
}

.scroll-holder {
  display: flex;
  flex-wrap: nowrap;
  animation: scroll 80s linear infinite;
}

.scroll-item-wrap {
  flex: 0 0 auto;
  align-items: center;
  display: flex;
}

.scroll-item {
  flex-direction: row;
  flex: none;
  align-self: center;
  display: flex;
}

.scroll-heading {
  color: #0d0d0d;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Changa One, Impact, sans-serif;
  font-size: 2.5rem;
  font-weight: 800;
}

.scroll-divider {
  background-color: #0d0d0d;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 1.25rem;
  height: 0.375rem;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  display: flex;
}

@keyframes scroll {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}
