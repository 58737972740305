#footer {
  background-color: #121212;
  color: white;
  text-align: center;
  padding: 1rem;
}

#footer a {
  color: #fff;
  font-weight: bold;
}
