/* Add this to your CSS file */
socials {
  background-color: #121212;
  box-shadow: 0 2px 8px #121212;
  margin-top: 1rem;
}

#socials h3 {
  color: #fff;
  font-size: 3rem;
}

.social-links {
  align-items: center;
  display: inline;
}

.social-links img {
  width: auto;
  height: 40px;
  margin: 0rem 4rem 2rem 4rem;
}
