.layout-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
  position: relative;
}

.image-wrapper {
  align-self: start;
  position: relative;
}

.roadmap-image {
  width: 100%;
  border-radius: 10px;
}

.roadmap-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
}

.roadmap-cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.roadmap-card {
  background-color: #3861fb;
  border-radius: 10px;
  padding: 1rem;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.roadmap-card-details {
  margin-bottom: 2rem;
}

.roadmap-title {
  color: #ffffff;
  font-size: 50px;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(131deg, #fff, #fe6f39 51%, #fbe200);
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 0;
}

.paragraph {
  color: #fff;
}

.roadmap-img-box {
  display: flex;
  justify-content: center;
}

.roadmap-img {
  width: 50px;
  height: auto;
}

.buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.button-4 {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #ff6f61;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
}

.button-4._1 {
  margin-right: 1rem;
}

.button-4:hover {
  background-color: #ff856e;
}

@media screen and (max-width: 768px) {
  .layout-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .roadmap-wrapper {
    margin-left: 0;
  }

  .roadmap-title {
    font-size: 2rem;
  }

  .roadmap-card {
    padding: 0.5rem;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .button-4 {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .roadmap-wrapper {
    margin-left: 50px;
  }

  .roadmap-title {
    font-size: 3rem;
  }

  .roadmap-card {
    padding: 0.75rem;
  }
}
